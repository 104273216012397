// Note: WILL NOT work on VFR videos
$(window).on('video-player:ready', function() {
  var totalFrames = null;
  var canvas = null
  var $canvas = null
  var drawnElement = null
  var $objectList = null

  function initResizeObserver() {
    if (typeof ResizeObserver == 'undefined') return // not supported

    element = $('.bmpui-ui-seekbar')[0]

    // There's an optimization here to only run at most every n ms
    // This prevents too much CPU usage when resizing the browser window.
    const resizeObserver = new ResizeObserver(function(entries) {
      observer = this
      observer.unobserve(element)

      setTimeout(function() {
        canvas.remove()
        initCanvas()
        if (drawnElement) { drawObjectInTimeline() }

        observer.observe(element)
      }, 500)
    })

    resizeObserver.observe(element);
  }

  function initTimeline() {
    totalFrames = $objectList.data('totalFrames')

    initCanvas()
    initObjectElements()
    initResizeObserver()
  }

  function initCanvas() {
    var $seekbar = $('.bmpui-ui-seekbar')
    var seekWidth = $seekbar.width()
    var offset = $seekbar.position().left + 7 // 6 is margin

    $canvas = $('<canvas class="video-player__timeline" width=' + seekWidth  + 'px>')
    $('.video-player').after($canvas)

    canvas = $canvas[0]
    canvas.style = 'margin-left: ' + offset + 'px;'
  }

  function initObjectElements() {
    $objectList.removeClass('hidden')

    var $objectElements = $objectList.find('.object-detection-list__item')

    $objectElements.on('click', handleObjectElementClicked)
    $objectElements.on('mouseover', handleObjectElementMouseover)
    $objectElements.on('mouseout', handleObjectElementMouseout)
  }

  function handleObjectElementClicked(event) {
    clear()

    if (drawnElement === event.currentTarget) {
      drawnElement = null
      return
    }

    drawObjectInTimeline(event.currentTarget, true)
  }

  function handleObjectElementMouseover(event) {
    clear()

    drawObjectInTimeline(event.currentTarget)
  }

  function handleObjectElementMouseout(event) {
    clear()

    // revert to what was selected previously
    drawnElement && drawObjectInTimeline()
  }

  function drawObjectInTimeline(objectElement = drawnElement, setDrawnElement = false) {
    $el = $(objectElement)
    $el.addClass('selected')
    color = $el.data('color')
    frames = $el.data('frames')

    $.each(frames, function(_, framePair) {
      drawFrames(framePair[0], framePair[1], color)
    })

    if (setDrawnElement) { drawnElement = objectElement }
  }

  function frameLocationOnSeekBar(frame) {
    var relativeLocation = frame / totalFrames
    var seekWidth = $('.bmpui-seekbar').width()

    return seekWidth * relativeLocation
  }

  function drawFrames(fromFrame, toFrame, color) {
    var ctx = canvas.getContext("2d");
    var fromFrameX = frameLocationOnSeekBar(fromFrame)

    // Make sure we get at least a pixel in if we're at the end
    if (fromFrameX >= canvas.width) { fromFrameX = canvas.width - 1 }
    var toFrameX = toFrame ? frameLocationOnSeekBar(toFrame) : canvas.width

    ctx.fillStyle = color;
    ctx.fillRect(fromFrameX, 95, toFrameX - fromFrameX, 100)
  }

  function clear() {
    var ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    $('.object-detection-list__item').removeClass('selected')
  }

  function init() {
    $objectList = $('.object-detection-list')

    if ($objectList.length === 0) return

    initTimeline()
  }

  init()
})
