$(document).on('ajax:complete', '[data-remote][data-target]', function(event) {
  var [data, _status, xhr] = event.detail;

  var location = data.getResponseHeader('Location');

  if (location != null) {
    return window.location = location;

  } else {
    container = $($(this).data("target"));
    container.replaceWith(data.responseText);

    if ($(this).data('target-post') == 'modal') {
      const modal = new bootstrap.Modal($($(this).data("target")).get(0));
      modal.show();
    }

    $(document).trigger('data-remote-target-refresh');
  }
});

