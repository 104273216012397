import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "container",
    "acceptRadioInput"
  ]

  acceptRadioInputTargetConnected(element) {
    if (element.checked) {
      this.swapContainers()
    }
  }

  swapContainers() {
    this.containerTargets.forEach((container) => {
      let embed = document.createElement("div")
      embed.innerHTML = container.dataset.content

      let parent = container.parentNode

      embed.childNodes.forEach((childNode) => {
        parent.insertBefore(childNode, container)
      })

      container.remove()
    });
  }
}
