$(document).on("click", ".filter-toggle", function () {
  const container = this.parentNode;
  const active = $("*:visible", this);

  if (active.hasClass("show-more")) {
    $(".is-more", container).removeClass("d-none");
    $(".filter-toggle .show-more", container).addClass("d-none");
    $(".filter-toggle .show-less", container).removeClass("d-none");
  } else {
    $(".is-more", container).addClass("d-none");
    $(".filter-toggle .show-more", container).removeClass("d-none");
    $(".filter-toggle .show-less", container).addClass("d-none");
  }
});
