import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "abortAction",
    "form",
    "input",
    "modalActions",
    "uploads",
    "uploadTemplate"
  ];

  static values = {
    path: String
  };

  connect() {
    this.element.addEventListener('change', this.handleFileUpload.bind(this));

    this.element.addEventListener("hide.bs.modal", (event) => {
      if (this.busy) {
        event.preventDefault();
      }
    });

    this.updateActionVisibility();
  }

  handleFileUpload() {
    this.busy = true;
    this.abort = false;
    this.updateActionVisibility();

    const files = this.inputTarget.files;
    let filesWithTemplate = [];

    Array.from(files).forEach(file => {
      const template = this.uploadTemplateTarget.cloneNode(true);

      template.classList.remove("template");
      template.querySelector(".filename").textContent = file.name;
      this.setFileSize(file, template.querySelector(".filesize"));

      this.uploadsTarget.appendChild(template);
      this.uploadsTarget.classList.remove("d-none");

      filesWithTemplate[filesWithTemplate.length] = {
        file: file,
        template: template
      }
    });

    const uploadChain = filesWithTemplate.reduce((promise, fileWithTemplate, index) => {
      const file = fileWithTemplate.file;
      const template = fileWithTemplate.template;

      return promise.then(() => {
        return this.uploadFile(file, template);
      });
    }, Promise.resolve());

    uploadChain.then(() => {
      this.inputTarget.value = "";
      this.busy = false;
      this.updateActionVisibility();
    }).catch(error => {
      this.busy = false;
      this.updateActionVisibility();
    });
  }

  abortUploads() {
    this.abort = true;
  }

  async uploadFile(file, template) {
    const minChunkSize = 1024 * 1024 * 5; // 5 MB
    const maxChunkSize = 1024 * 1024 * 1024 * 5; // 5 GB
    const maxChunkCount = 10000;

    let chunkSize = Math.ceil(file.size / maxChunkCount);

    if (chunkSize < minChunkSize) {
      chunkSize = minChunkSize;
    } else if (chunkSize > maxChunkSize) {
      chunkSize = maxChunkSize;
    }

    this.setProgress(template, 0, file.size);

    if (this.abort) {
      this.setFailure(template, "");
      return;
    }

    const upload = await this.createUpload();

    try {
      let start = 0;
      let partNumber = 1;
      let parts = [];
      const totalSize = file.size;

      while (start < totalSize) {
        const url = await this.getUploadUrl(upload, partNumber)

        const etag = await this.uploadFileChunk(url, file.slice(start, start + chunkSize));

        parts[parts.length] = { part_number: partNumber, etag: etag }

        start += chunkSize;
        partNumber += 1;

        const progress = Math.min((start / totalSize) * 100, 100);

        this.setProgress(template, progress, file.size);

        if (this.abort) {
          await this.abortUpload(upload);

          this.setFailure(template, "");

          return;
        }
      }

      await this.completeUpload(upload, parts, file.name);

      this.setSuccess(template);

    } catch (error) {
      await this.abortUpload(upload);

      this.setFailure(template, error);

      throw new Error(`Failed to upload: ${error}`);
    }
  }

  async uploadFileChunk(url, chunk, retries = 3) {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Length': chunk.length,
          'Content-Type': 'application/octet-stream',
        },
        body: chunk
      });

      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
      }

      const eTag = response.headers.get('ETag');
      if (!eTag) {
        throw new Error('ETag header is missing in the response.');
      }

      return eTag;
    } catch (error) {
      if (retries > 0) {
        console.warn(`Retrying upload... ${retries} attempts left`);

        await this.uploadFileChunk(url, chunk, retries - 1);
      } else {
        throw new Error(`Failed to upload chunk after retries: ${error}`);
      }
    }
  }

  async createUpload() {
    const response = await fetch(this.pathValue, {
      method: "POST",
      headers: { 'X-CSRF-Token': this.csrfToken() }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  }

  async getUploadUrl(upload, partNumber) {
    const response = await fetch(`${this.pathValue}/upload_url`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": this.csrfToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        key: upload.key,
        upload_id: upload.upload_id,
        part_number: partNumber
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    let data = await response.json();

    return data.url;
  }

  async completeUpload(upload, parts, name) {
    await fetch(`${this.pathValue}/complete`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": this.csrfToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        key: upload.key,
        upload_id: upload.upload_id,
        parts: parts,
        name: name
      })
    });
  }

  async abortUpload(upload) {
    await fetch(this.pathValue, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": this.csrfToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        key: upload.key,
        upload_id: upload.upload_id
      })
    });
  }

  csrfToken() {
    return document.querySelector('meta[name="csrf-token"]').content;
  }

  updateActionVisibility() {
    if (this.busy) {
      this.inputTarget.classList.add('d-none');
      this.abortActionTarget.classList.remove('d-none');
      this.modalActionsTargets.forEach(element => {
        element.classList.add('d-none');
      });
    } else {
      this.inputTarget.classList.remove('d-none');
      this.abortActionTarget.classList.add('d-none');
      this.modalActionsTargets.forEach(element => {
        element.classList.remove('d-none');
      });
    }
  }

  setFileSize(file, element) {
    const sizeInBytes = file.size;
    let size, unit;

    if (sizeInBytes < 1024) {
      size = sizeInBytes;
      unit = 'Bytes';
    } else if (sizeInBytes < 1024 * 1024) {
      size = (sizeInBytes / 1024).toFixed(2);
      unit = 'KB';
    } else if (sizeInBytes < 1024 * 1024 * 1024) {
      size = (sizeInBytes / (1024 * 1024)).toFixed(2);
      unit = 'MB';
    } else {
      size = (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2);
      unit = 'GB';
    }

    element.textContent = `${size} ${unit}`;
  }

  setProgress(template, progress, fileSize) {
    const progressContainer = template.querySelector(".progress-container");
    progressContainer.classList.remove("d-none");

    const progressInteger = Math.floor(progress);

    const progressBar = template.querySelector(".progress-bar");
    progressBar.style.width = `${progressInteger}%`;

    let progressText;
    if (progress < 5) {
      progressText = progress.toFixed(2);
    } else {
      progressText = Math.floor(progress);
    }

    let speedText = "...";
    let etaText = "...";

    if (progress === 0) {
      template.dataset.startTime = Date.now();
    } else {
      const startTime = parseInt(template.dataset.startTime, 10);
      const elapsedTime = (Date.now() - startTime) / 1000;

      const speed = ((fileSize * (progress / 100)) / elapsedTime) / 1024;
      speedText = speed > 1024
        ? `${(speed / 1024).toFixed(2)} MB/s`
        : `${speed.toFixed(2)} KB/s`;

      const remainingProgress = 100 - progress;
      const eta = (elapsedTime / progress) * remainingProgress;
      const etaMinutes = Math.floor(eta / 60);
      const etaSeconds = Math.floor(eta % 60);
      etaText = `${etaMinutes}m ${etaSeconds}s`;
    }

    const progressValue = template.querySelector(".progress_value");
    progressValue.textContent = `${progressText}% | ${speedText} | ETA: ${etaText}`;
  }

  setFailure(template, message) {
    const failure = template.querySelector(".failure");
    const failureMessage = template.querySelector(".failure .message");

    failureMessage.textContent = message;
    failure.classList.remove("d-none");

    const progressValue = template.querySelector(".progress_value");
    progressValue.classList.add("d-none");

    const progressContainer = template.querySelector(".progress-container");
    progressContainer.classList.add("d-none");
  }

  setSuccess(template) {
    const success = template.querySelector(".success");

    success.classList.remove("d-none");

    const progressValue = template.querySelector(".progress_value");
    progressValue.classList.add("d-none");

    const progressContainer = template.querySelector(".progress-container");
    progressContainer.classList.add("d-none");
  }
}
