$(document).on(
  "turbo:load cocoon:after-insert show.bs.modal data-remote-target-refresh select2:refresh",
  function () {
    initSelect2();
  }
);

$(document).on("turbo:before-render", function () {
  $("select[data-select2-id]").select2("destroy");
});

window.initSelect2 = function () {
  $("select:not(.tags-select):not([data-no-select2])").each(function () {
    $(this).select2({
      theme: "bootstrap-5",
      width: "100%",
    });
  });

  $(".ajax-select:not([data-no-select2])").each(function () {
    var options = {
      theme: "bootstrap-5",
      ajax: {
        url: $(this).data("source"),
        dataType: "json",
        delay: 250,
        data: function (params) {
          return {
            q: params.term,
            page: params.page,
            per: 10,
            searching: true,
          };
        },
        processResults: function (data, page) {
          return {
            results: data,
          };
        },
      },
    };

    $(this).select2(options);
  });

  $(".tags-select").each(function () {
    var options = {
      theme: "bootstrap-5",
      tags: true,
    };

    $(this).select2(options);

    if ($(this).hasClass("auto-submit")) {
      $(this).on("change", function () {
        var form = $(this).parents("form").get(0);
        window.Rails.fire(form, "submit");
      });
    }
  });
};
