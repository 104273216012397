import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "hints"];

  connect() {
    this.update();

    // because the action doesn't work with select2
    $(this.inputTarget).on("select2:select", () => {
      this.update();
    });
  }

  update() {
    let currentAssetType =
      this.inputTarget.querySelector("option:checked").dataset.assetType;

    this.hintsTarget.querySelectorAll("div").forEach((hint) => {
      if (hint.dataset.assetType == currentAssetType) {
        hint.classList.remove("d-none");
      } else {
        hint.classList.add("d-none");
      }
    });
  }
}
