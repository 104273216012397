var checkedItems,
  handleConfirmBulkDelete,
  initBulkActions,
  initTableWithCheckbox,
  showHideTableBulkActions;

$(document).on("turbo:load", function () {
  initTableWithCheckbox();
  return showHideTableBulkActions();
});

checkedItems = function () {
  return $("table.table-with-checkbox tbody input[type=checkbox]:checked");
};

handleConfirmBulkDelete = function (event) {
  var $target, csrfParam, csrfToken, form;
  event.preventDefault();
  event.stopPropagation();
  $target = $(event.target);
  csrfParam = $("meta[name=csrf-param]").prop("content");
  csrfToken = $("meta[name=csrf-token]").prop("content");
  form = $("<form>")
    .addClass("hidden")
    .prop("method", "post")
    .prop("action", $target.prop("href"))
    .append("<input type='hidden' name='_method' value='delete' />")
    .append(
      "<input type='hidden' name='" +
        csrfParam +
        "' value='" +
        csrfToken +
        "' />"
    )
    .appendTo(document.body);
  checkedItems().each(function () {
    return form.append(this.cloneNode(true));
  });
  form.submit();
  return false;
};

initBulkActions = function () {
  return $(".bulk-actions .bulk-delete").on(
    "confirm:complete",
    handleConfirmBulkDelete
  );
};

initTableWithCheckbox = function () {
  return $("table.table-with-checkbox").each(function () {
    var table;
    table = this;
    $("thead input.toggle-all[type=checkbox]", this).on("change", function () {
      $("tbody input[type=checkbox]", table).prop("checked", this.checked);
      return showHideTableBulkActions();
    });
    $("tbody input[type=checkbox]", this).on("change", function () {
      var all_checked;
      all_checked =
        $("tbody input[type=checkbox]:not(:checked)", table).length === 0;
      $("thead input.toggle-all[type=checkbox]", table).prop(
        "checked",
        all_checked
      );
      return showHideTableBulkActions();
    });
    return initBulkActions();
  });
};

showHideTableBulkActions = function () {
  if (checkedItems().length > 0) {
    $(".bulk-actions input[type=checkbox]").remove();

    form = document.querySelector(".bulk-actions .inputs");

    checkedItems().each(function () {
      form.append(this.cloneNode(true));
    });

    return $(".bulk-actions").show();
  } else {
    return $(".bulk-actions").hide();
  }
};

$(document).on("turbo:load", function () {
  [].forEach.call(
    document.querySelectorAll(".list-search-form"),
    function (form) {
      [].forEach.call(
        form.querySelectorAll(".filter-select-group"),
        function (group) {
          [].forEach.call(group.querySelectorAll("li a"), function (link) {
            link.addEventListener("click", function (event) {
              event.stopPropagation();
              event.preventDefault();

              // set value
              group.querySelector("input").value = link.dataset.method;
              group.querySelector("span.method").innerText = link.innerText;

              // close dropdown
              const dropdown = new bootstrap.Dropdown(group.querySelector("button"));
              dropdown.toggle();

              // unset active
              [].forEach.call(
                group.querySelectorAll("li.active"),
                function (li) {
                  li.classList.remove("active");
                }
              );

              // set active
              link.parentNode.classList.add("active");

              // hide or show input
              const input_group = group.parentNode;
              if (
                link.dataset.method == "all" ||
                link.dataset.method == "b" ||
                link.dataset.method == "nb"
              ) {
                input_group
                  .querySelector("input.form-control")
                  .classList.add("hide");
              } else {
                input_group
                  .querySelector("input.form-control")
                  .classList.remove("hide");
              }
            });
          });
        }
      );

      [].forEach.call(
        form.querySelectorAll(".filter-select"),
        function (group) {
          [].forEach.call(group.querySelectorAll("li a"), function (link) {
            link.addEventListener("click", function (event) {
              event.stopPropagation();
              event.preventDefault();

              const select = group.querySelector("select");
              const values = [];
              const labels = [];

              // set or reset value
              if (link.dataset.reset) {
                [].forEach.call(
                  group.querySelectorAll("li.active"),
                  function (selected) {
                    selected.classList.remove("active");
                  }
                );

                $(select).val([]);
              } else {
                if (link.parentNode.classList.contains("active")) {
                  link.parentNode.classList.remove("active");
                } else {
                  link.parentNode.classList.add("active");

                  // deselect others unless multiple
                  if (group.dataset.multiple == "false") {
                    [].forEach.call(
                      group.querySelectorAll("li.active"),
                      function (selected) {
                        if (selected != link.parentNode) {
                          selected.classList.remove("active");
                        }
                      }
                    );
                  }
                }

                [].forEach.call(
                  group.querySelectorAll("li.active:not(.all) a"),
                  function (selected) {
                    values[values.length] = selected.dataset.value;
                    labels[labels.length] = selected.dataset.label;
                  }
                );

                $(select).val(values);
              }

              // adjust label
              if (values.length == 0) {
                group.querySelector(
                  "span.value"
                ).innerText = group.querySelector("span.value").dataset.all;

                group.querySelector("li.all").classList.add("active");
              } else {
                if (labels.length == 1) {
                  group.querySelector("span.value").innerText = labels.join(
                    " + "
                  );
                } else {
                  group.querySelector("span.value").innerText = labels.length;
                }

                group.querySelector("li.all").classList.remove("active");
              }

              // close dropdown
              if (group.dataset.multiple == "false") {
                const dropdown = new bootstrap.Dropdown(group.querySelector("button"));
                dropdown.toggle();
              }
            });
          });
        }
      );

      [].forEach.call(
        form.querySelectorAll(".btn-online-status"),
        function (online_status) {
          [].forEach.call(online_status.querySelectorAll("a"), function (link) {
            link.addEventListener("click", function (event) {
              event.stopPropagation();
              event.preventDefault();

              online_status.querySelector("input").value = this.dataset.value;
              form.submit();
            });
          });
        }
      );

      // prevent dropdown close on column checkbox select
      [].forEach.call(
        form.querySelectorAll(".list-filter-column-checkbox"),
        function (li) {
          li.addEventListener("click", function (event) {
            event.stopPropagation();
          });
        }
      );
    }
  );
});
