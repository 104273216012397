import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  static values = { url: String };

  connect() {
    this.element.remove();

    Turbo.clearCache();
    Turbo.visit(this.urlValue);
  }
}
