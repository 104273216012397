$(document).on("turbo:load", function () {
  // function fetchWidgetById(id) {
  //   return $('.mod-presentation__widget[data-widget-id="' + id + '"]')
  // }

  // function setupWidgetIndicator() {
  //   $('.mod-presentation-editor__sidebar__widgets__widget')
  //     .on('mouseover', function(event) {
  //       fetchWidgetById($(event.currentTarget).data('widget-id'))
  //         .addClass('highlight')
  //     })
  //     .on('mouseout', function(event) {
  //       fetchWidgetById($(event.currentTarget).data('widget-id'))
  //         .removeClass('highlight')
  //     })
  // }

  function addWidgetBeforeColumn(form, target) {
    var $target = $(target);

    addNewWidget(form, $target.data("column") - 1, $target.data("row"));
  }

  function addWidgetAfterColumn(form, target) {
    var $target = $(target);

    addNewWidget(form, $target.data("column") + 1, $target.data("row"));
  }

  function addWidgetBeforeRow(form, target) {
    var $target = $(target);

    addNewWidget(form, 1, $target.data("row") - 1);
  }

  function addWidgetAfterRow(form, target) {
    var $target = $(target);

    addNewWidget(form, 1, $target.data("row") + 1);
  }

  function addNewWidget(form, column, row) {
    $(form)
      .append(
        '<input name="presentation_widget[column]" value="' +
          column +
          '" type="hidden" />'
      )
      .append(
        '<input name="presentation_widget[row]" value="' +
          row +
          '" type="hidden" />'
      );

    form.submit();
  }

  function targetIsBeforeColumn(event, offset) {
    var cursorFromLeft = event.pageX - offset.left;
    var before = window.getComputedStyle(event.currentTarget, ":before");
    var beforeWidth = parseInt(before.width) || 0;
    var beforeOffset = parseInt(before.left) || 0;

    return (
      cursorFromLeft - beforeOffset <= beforeWidth &&
      cursorFromLeft >= beforeOffset
    );
  }

  function targetIsAfterColumn(event, offset) {
    var cursorFromLeft = event.pageX - offset.left;
    var after = window.getComputedStyle(event.currentTarget, ":after");
    var afterWidth = parseInt(after.width) || 0;
    var afterOffset = parseInt(after.right) || 0;
    var afterEnd = event.currentTarget.clientWidth;
    var afterStart = afterEnd - afterWidth - afterOffset;

    return cursorFromLeft >= afterStart && cursorFromLeft <= afterEnd;
  }

  function targetIsBeforeRow(event, offset) {
    var cursorFromTop = event.pageY - offset.top;
    var before = window.getComputedStyle(event.currentTarget, ":before");
    var beforeHeight = parseInt(before.height) || 0;
    var beforeOffset = parseInt(before.top) || 0;

    return (
      cursorFromTop - beforeOffset <= beforeHeight &&
      cursorFromTop >= beforeOffset
    );
  }

  function targetIsAfterRow(event, offset) {
    var cursorFromTop = event.pageY - offset.top;
    var after = window.getComputedStyle(event.currentTarget, ":after");
    var afterHeight = parseInt(after.height) || 0;
    var afterOffset = parseInt(after.bottom) || 0;
    var afterEnd = event.currentTarget.clientHeight;
    var afterStart = afterEnd - afterHeight - afterOffset;

    return cursorFromTop >= afterStart && cursorFromTop <= afterEnd;
  }

  function maybeAddRowWidget(form, event) {
    var $target = $(event.currentTarget);
    var offset = $target.offset();

    if (targetIsBeforeRow(event, offset)) {
      console.log("add row before");
      addWidgetBeforeRow(form, $target);
      return;
    }

    if (targetIsAfterRow(event, offset)) {
      console.log("add row after");
      addWidgetAfterRow(form, $target);
    }
  }

  function maybeAddColumnWidget(form, event) {
    var $target = $(event.currentTarget);
    var offset = $target.offset();

    if (targetIsBeforeColumn(event, offset)) {
      console.log("add column before");
      addWidgetBeforeColumn(form, $target);
      event.stopPropagation(); // don't propagate to rows
      return;
    }

    if (targetIsAfterColumn(event, offset)) {
      console.log("add column after");
      addWidgetAfterColumn(form, $target);
      event.stopPropagation(); // don't propagate to rows
    }
  }

  function setupNewWidgetTools() {
    $("#new_presentation_widget").on("submit", function (submitEvent) {
      event.preventDefault();
      event.stopPropagation();

      $(".mod-presentation-editor").addClass("add-widget-mode");
      $(
        ".mod-presentation__widget-row--not-full .mod-presentation__widget",
        ".mod-presentation-editor"
      ).on("click", function (positionEvent) {
        maybeAddColumnWidget(submitEvent.currentTarget, positionEvent);
      });
      $(".mod-presentation__widget-row", ".mod-presentation-editor").on(
        "click",
        function (positionEvent) {
          maybeAddRowWidget(submitEvent.currentTarget, positionEvent);
        }
      );
      // We don't bother removing this event or class right now, as we expect the user to post to create a new widget.
      // If we ever add a cancel, we'll need to do that.
    });
  }

  // Edit mode

  // function setupEditMode() {
  //   setupWidgetEditor()

  //   $('.mod-presentation__widget__edit-anchor').on('click', function() {
  //     var $widget = $(event.currentTarget).parent('.mod-presentation__widget')

  //     enableWidgetEditor($widget)
  //   })
  // }

  // function enableWidgetEditor($widget) {
  //   var $editor = $('.mod-presentation__widget-editor')
  //   $editor.data('$widget', $widget)
  //   updateWidgetEditorButtons($editor, $widget)

  //   // Make the currently edited widget visible via its anchor
  //   $('.mod-presentation__widget__edit-anchor--editing').removeClass('mod-presentation__widget__edit-anchor--editing')
  //   $widget.children('.mod-presentation__widget__edit-anchor').addClass('mod-presentation__widget__edit-anchor--editing')

  //   $editor.removeClass('hidden')

  //   // TODO: show it over the widget
  // }

  // function updateWidgetEditorButtons($editor, $widget) {
  //   var column = $widget.data('column')
  //   var width = $widget.data('width')
  //   var maxWidth = $editor.data('max-width')

  //   var $moveLeftButton = $editor.find('.mod-presentation__widget-editor__move-left')
  //   var $moveRightButton = $editor.find('.mod-presentation__widget-editor__move-right')
  //   var $growLeftButton = $editor.find('.mod-presentation__widget-editor__grow-left')
  //   var $growRightButton = $editor.find('.mod-presentation__widget-editor__grow-right')
  //   var $shrinkLeftButton = $editor.find('.mod-presentation__widget-editor__shrink-left')
  //   var $shrinkRightButton = $editor.find('.mod-presentation__widget-editor__shrink-right')

  //   var $previousSibling = $widget.prev('.mod-presentation__widget')
  //   var $nextSibling= $widget.next('.mod-presentation__widget')

  //   if (column > 1) {
  //     $moveLeftButton.prop('disabled', false)
  //   } else {
  //     $moveLeftButton.prop('disabled', true)
  //   }

  //   if ($nextSibling.length > 0) {
  //     $moveRightButton.prop('disabled', false)
  //   } else {
  //     $moveRightButton.prop('disabled', true)
  //   }

  //   if (column > 1 && $previousSibling.length > 0 && $previousSibling.data('width') > 1) {
  //     $growLeftButton.prop('disabled', false)
  //   } else {
  //     $growLeftButton.prop('disabled', true)
  //   }

  //   if ($nextSibling.length >  0 && $nextSibling.data('width') > 1) {
  //     $growRightButton.prop('disabled', false)
  //   } else {
  //     $growRightButton.prop('disabled', true)
  //   }

  //   if ($widget.data('width') > 1) {
  //     $shrinkLeftButton.prop('disabled', false)
  //     $shrinkRightButton.prop('disabled', false)
  //   } else {
  //     $shrinkLeftButton.prop('disabled', true)
  //     $shrinkRightButton.prop('disabled', true)
  //   }
  // }

  // function setupWidgetEditor() {
  //   var $editor = $('.mod-presentation__widget-editor')
  //   setupWidgetEditorMoveLeft($editor)
  //   setupWidgetEditorMoveRight($editor)
  //   setupWidgetEditorGrowLeft($editor)
  //   setupWidgetEditorGrowRight($editor)
  //   setupWidgetEditorShrinkLeft($editor)
  //   setupWidgetEditorShrinkRight($editor)
  // }

  // function setupWidgetEditorMoveLeft($editor) {
  //   var $button = $editor.find('.mod-presentation__widget-editor__move-left')

  //   $button.on('click', function(_event) {
  //     var $widget = $editor.data('$widget')
  //     moveWidgetLeft($widget)
  //     enableWidgetEditor($widget)
  //   })
  // }

  // function moveWidgetLeft($widget) {
  //   $previousWidget = $widget.prev('.mod-presentation__widget')

  //   switchWidgetColumns($previousWidget, $widget)
  // }

  // function setupWidgetEditorMoveRight($editor) {
  //   var $button = $editor.find('.mod-presentation__widget-editor__move-right')

  //   $button.on('click', function(_event) {
  //     var $widget = $editor.data('$widget')
  //     moveWidgetRight($widget)
  //     enableWidgetEditor($widget)
  //   })
  // }

  // function moveWidgetRight($widget) {
  //   $nextWidget = $widget.next('.mod-presentation__widget')
  //   switchWidgetColumns($widget, $nextWidget)
  // }

  // function switchWidgetColumns($widget1, $widget2) {
  //   $widget2.after($widget1)

  //   var newColumn = $widget2.data('column')

  //   $widget2.data('column', $widget1.data('column'))
  //   $widget1.data('column', newColumn)
  // }

  // function setupWidgetEditorGrowLeft($editor) {
  //   var $button = $editor.find('.mod-presentation__widget-editor__grow-left')

  //   $button.on('click', function(_event) {
  //     var $widget = $editor.data('$widget')
  //     growWidgetLeft($widget)
  //     enableWidgetEditor($widget)
  //   })
  // }

  // function growWidgetLeft($widget) {
  //   $previousWidget = $widget.prev('.mod-presentation__widget')

  //   changeWidth($previousWidget, -1)
  //   changeWidth($widget, 1)
  //   $previousWidget.data('column', $previousWidget.data('column') - 1)
  // }

  // function setupWidgetEditorGrowRight($editor) {
  //   var $button = $editor.find('.mod-presentation__widget-editor__grow-right')

  //   $button.on('click', function(_event) {
  //     var $widget = $editor.data('$widget')
  //     growWidgetRight($widget)
  //     enableWidgetEditor($widget)
  //   })
  // }

  // function growWidgetRight($widget) {
  //   $nextWidget = $widget.next('.mod-presentation__widget')

  //   changeWidth($nextWidget, -1)
  //   changeWidth($widget, 1)
  //   $nextWidget.data('column', $previousWidget.data('column') + 1)
  // }

  // function setupWidgetEditorShrinkLeft($editor) {
  //   var $button = $editor.find('.mod-presentation__widget-editor__shrink-left')

  //   $button.on('click', function(_event) {
  //     var $widget = $editor.data('$widget')
  //     shrinkWidgetLeft($widget)
  //     enableWidgetEditor($widget)
  //   })
  // }

  // function setupWidgetEditorShrinkRight($editor) {
  //   var $button = $editor.find('.mod-presentation__widget-editor__shrink-right')

  //   $button.on('click', function(_event) {
  //     var $widget = $editor.data('$widget')
  //     shrinkWidgetRight($widget)
  //     enableWidgetEditor($widget)
  //   })
  // }

  // function shrinkWidgetLeft($widget) {
  //   $previousWidget = $widget.prev('.mod-presentation__widget')

  //   changeWidth($widget, -1)
  //   changeWidth($previousWidget, 1)

  //   $widget.data('column', $widget.data('column') + 1)
  // }

  // function shrinkWidgetRight($widget) {
  //   $nextWidget = $widget.next('.mod-presentation__widget')

  //   changeWidth($widget, -1)
  //   changeWidth($nextWidget, 1)

  //   $nextWidget.data('column', $nextWidget.data('column') - 1)
  // }

  // function changeWidth($widget, changeBy) {
  //   var oldWidth = $widget.data('width')
  //   var newWidth = oldWidth + changeBy

  //   $widget.removeClass('col-sm-' + oldWidth)
  //   $widget.addClass('col-sm-' + newWidth)
  //   $widget.data('width', newWidth)
  // }

  function main() {
    // setupWidgetIndicator()
    setupNewWidgetTools();
    // setupEditMode()
  }

  main();
});
