// var moment = require("moment");
require("vendor/tempusdominus-bootstrap");

$(document).on("turbo:load", function () {
  $("input.datetimepicker").each(function () {
    const element = $(this);

    element.on("blur", function () {
      $(this).data("datetimepicker").hide();
    });

    element.datetimepicker({
      sideBySide: true,
      buttons: { showToday: true, showClear: true, showClose: true },
      format: element.data("datetimepicker-format"),
    });
  });
});
