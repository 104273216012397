import Chart from "chart.js";

window.charts = [];

function loadChart() {
  var chart = $(".chart:not(.loaded):first");

  if (chart.length > 0) {
    $.getJSON(chart.data("source"), function (data) {
      chart.addClass("loaded");

      $.each(data.data.datasets, function (dataset_index) {
        if (this.pointStyle) {
          let logo = new Image(10, 10);
          logo.src = data.data.datasets[dataset_index].pointStyle;
          data.data.datasets[dataset_index].pointStyle = logo;
        } else {
          data.data.datasets[dataset_index].pointStyle = "circle";
        }
      });

      window.charts[charts.length] = new Chart(chart.get(0), data);

      loadChart();
    });
  }
}

function updateChartHighlight() {
  $.each(window.charts, function () {
    if (this.highlightedLabel != this.currentHighlightedLabel) {
      let chart = this;

      $.each(chart.data.datasets, function (dataset_index) {
        if (!chart.data.datasets[dataset_index].originalBackgroundColor) {
          chart.data.datasets[dataset_index].originalBackgroundColor =
            chart.data.datasets[dataset_index].backgroundColor;
        }

        if (chart.highlightedLabel) {
          if (
            chart.highlightedLabel.text ==
            chart.data.datasets[dataset_index].label
          ) {
            chart.data.datasets[dataset_index].borderColor =
              chart.data.datasets[dataset_index].originalBackgroundColor;
          } else {
            chart.data.datasets[dataset_index].borderColor = "#F0F0F0";
          }
        } else {
          chart.data.datasets[dataset_index].borderColor =
            chart.data.datasets[dataset_index].originalBackgroundColor;
        }
      });

      chart.chart.update();

      this.currentHighlightedLabel = this.highlightedLabel;
    }
  });

  window.setTimeout(updateChartHighlight, 100);
}

Chart.defaults.global.legend.onHover = function (_event, label) {
  this.chart.highlightedLabel = label;
};

Chart.defaults.global.legend.onLeave = function (_event, label) {
  this.chart.highlightedLabel = null;
};

$(document).on("turbo:load", function () {
  loadChart();
  window.setTimeout(updateChartHighlight, 100);
});
