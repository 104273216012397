import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    target: String
  }

  connect() {
    this.element.addEventListener("click", (event) => {
      document.querySelector(this.targetValue).submit();

      event.preventDefault();
      event.stopPropagation();
      return false;
    });
  }
}
