import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["startsAt", "endsAt", "venue", "alert"]
  static values = {
    url: String,
    id: String
  }

  connect() {
    this.abortController = new AbortController();
    this.updateAlert();
  }

  updateAlert() {
    this.abortController.abort();

    const abortSignal = this.abortController.signal;
    fetch(this.requestParams(), { abortSignal })
      .then(response => {
        response.json().then(data => {
          this.setAlert(data.alert);
        });
      })
      .catch(_error => {
        // don't bug the user with something they can't fix
        this.setAlert(false);
      });
  }

  setAlert(value) {
    if (value) {
      this.alertTarget.classList.remove('d-none');
      this.alertTarget.innerText = value;
    } else {
      this.alertTarget.classList.add('d-none');
    }
  }

  requestParams() {
    const urlParams = new URLSearchParams({
      id: this.idValue,
      starts_at: this.startsAtTarget.value,
      ends_at: this.endsAtTarget.value,
      venue: this.venueTarget.value
    });

    return this.urlValue + '?' + urlParams;
  }
}
