import { Controller } from "@hotwired/stimulus";

import Dropzone from "dropzone";

Dropzone.autoDiscover = false;

export default class extends Controller {
  static values = {
    dropTargetUrl: String
  }

  connect() {
    this.dropzone = false;
    this.maybeCreateDropzone();
  }

  maybeCreateDropzone() {
    if (!this.dropzone) {
      this.createDropzone();
    }
  }

  createDropzone() {
    this.dropzone = new Dropzone(this.element, {
      url: "#",
      method: "post",
      maxFilesize: 1000000000,
      parallelUploads: 1,
      uploadMultiple: false,

      transformFile: this.dropzoneTransformFile
    });

    this.dropzone.context = this;
    this.dropzone.on("sending", this.dropzoneSending);
  }

  async dropzoneTransformFile(file, done) {
    let initData = await this.context.dropzoneInitUpload(file.name, file.type);
    file.presign = initData.presign;
    file.fileName = initData.name;

    done(file);
  }

  dropzoneInitUpload(name, contentType) {
    let dropTargetUrlValue = this.dropTargetUrlValue;

    return new Promise(function (resolve, reject) {
      $.ajax({
        url: dropTargetUrlValue,
        data: { "file": name, "content_type": contentType },
        type: "POST"
      })
        .done(function (response) {
          if (response.status) {
            resolve(response.data);
          } else {
            reject()
          }
        })
        .fail(function () {
          reject()
        });
    });
  }

  dropzoneSending(file, xhr, formData) {
    xhr.open("POST", file.presign.url, true);

    Object.keys(file.presign.inputs).forEach(function (key) {
      console.log(key, file.presign.inputs[key]);
      formData.append(key, file.presign.inputs[key]);
    });

    let _send = xhr.send
    xhr.send = function () {
      _send.call(xhr, formData)
    }
  }
}
