import consumer from "./consumer";

const initPollChatChannel = (pollResult) => {
  const id = pollResult.dataset["id"];

  consumer.subscriptions.create(
    { channel: "PollChannel", id: id },
    {
      received(records) {
        var container = document.body.querySelector(
          ".poll-result[data-id='" + id + "']"
        );

        for (let record of records) {
          container.querySelector(
            ".poll-result-answer[data-for='" +
              record.answer +
              "'] .progress-bar"
          ).style.width = record.percentage + "%";
        }
      },
    }
  );
};

window.initPollChannels = () => {
  consumer.subscriptions.subscriptions.forEach((subscription) => {
    if (/pollChannel/.test(subscription.identifier)) {
      subscription.unsubscribe();
    }
  });

  const pollResults = document.getElementsByClassName("poll-result");

  for (let pollResult of pollResults) {
    initPollChatChannel(pollResult);
  }
};

document.addEventListener("turbo:load", window.initPollChannels);
