$(document).on("turbo:load", function () {
  selectSearchFilter = function (element) {
    $element = $(element);
    $filter = $element.closest(".mod-search-movies__filter");

    newValue = $element.data("id");
    $currentLabel = $filter.find(".current-label");
    $currentValue = $filter.find(".current-value");

    if (newValue) {
      $currentLabel.text($element.text());
      $currentValue.prop("disabled", false).val(newValue);
    } else {
      $currentLabel.text("");
      $currentValue.prop("disabled", true);
    }

    $currentValue.trigger("change");
  };

  updateSearchFlagState = function (element) {
    $element = $(element);
    $parent = $element.closest(".mod-search-movies__flag");

    if ($element.is(":checked")) {
      $parent.addClass("checked");
    } else {
      $parent.removeClass("checked");
    }
  };

  initSearchFlags = function () {
    $(".mod-search-movies__flag input").each(function (_, element) {
      updateSearchFlagState(element);
    });
    $(".mod-search-movies").on(
      "change",
      ".mod-search-movies__flag input",
      function (event) {
        updateSearchFlagState(event.currentTarget);
      }
    );
  };

  initSearch = function () {
    $(".mod-search-movies").on(
      "click",
      ".mod-search-movies__filter .dropdown-menu a",
      function (event) {
        selectSearchFilter(event.currentTarget);
      }
    );
  };

  enableSearchButton = function () {
    $(".mod-search-movies").addClass("is-searching");
  };

  initQueryInput = function () {
    $(".mod-search-movies").on("keyup change", "input", enableSearchButton);
    $(".mod-search-movies__query-icon").on("click", function () {
      $(this).closest("form").submit();
    });
  };

  initQueryInput();
  initSearch();
  initSearchFlags();
});

$(document).on("click", ".mod-search-movies__filter-checkbox", function () {
  var checked = this;


  if (!this.classList.contains('skip-deselect')) {
    // deselect others
    $(this).closest('.filter-items').find('input[type=checkbox]:checked').each(function() {
      if (this.value != checked.value) {
        this.checked = false;
      }
    });
  }

  // submit
  $(this).closest("form").submit();
});
