import noUiSlider from "nouislider";
import wNumb from "wnumb";

$(document).on("turbo:load", function () {
  $(".range-input input[type=hidden]").each(function () {
    let options = {
      start: this.value.split("-"),
      step: parseFloat(this.step),

      range: {
        min: parseFloat(this.min),
        max: parseFloat(this.max),
      },
    };

    let decimals = this.getAttribute("data-decimals") || 0;

    let tooltip = !(this.getAttribute("data-tooltip") === null);
    if (tooltip) {
      options.tooltips = wNumb({ decimals: decimals });
    }

    let pips = !(this.getAttribute("data-pips") === null);
    if (pips) {
      options.pips = {
        mode: "steps",
        format: wNumb({ decimals: decimals }),
      };
    }

    let fill = this.getAttribute("data-fill");
    if (fill) {
      if (fill == "left") {
        options.connect = "lower";
      } else if (fill == "right") {
        options.connect = "upper";
      } else if (fill == "between") {
        options.connect = [false, true, false];
      }
    }

    noUiSlider
      .create(this.nextElementSibling, options)
      .on("update", (values, _handle) => {
        this.value = values.join("-");
      });
  });
});
