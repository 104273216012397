import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (document.body.dataset.bsTheme) {
      document.documentElement.dataset.bsTheme = document.body.dataset.bsTheme
    } else if (document.body.classList.contains("bg-is-dark")) {
      document.documentElement.dataset.bsTheme = "dark";
    } else {
      document.documentElement.dataset.bsTheme = "light";
    }
  }
}
