document.addEventListener('turbo:load', function () {
  var _paq = window._paq = window._paq || [];
  _paq.push(['enableLinkTracking']);
  _paq.push(['enableHeartBeatTimer']);

  if (window.previousPageUrl) {
    _paq.push(['setReferrerUrl', window.previousPageUrl]);
  }
  _paq.push(['setCustomUrl', window.location.href]);
  _paq.push(['setDocumentTitle', document.title]);
  _paq.push(['trackPageView']);

  window.previousPageUrl = window.location.href;

  var dataNode = document.getElementById('matomo');

  if (!dataNode) { return }

  if (dataNode && dataNode.dataset.userId) {
    _paq.push(['setUserId', dataNode.dataset.userId]);
  } else {
    _paq.push(['resetUserId']);
  }

  if (dataNode && dataNode.dataset.matomoGoalIds) {
    var goalIds = JSON.parse(dataNode.dataset.matomoGoalIds);
    for (var goalIdIndex = 0; goalIdIndex < goalIds.length; goalIdIndex++) {
      _paq.push(['trackGoal', goalIds[goalIdIndex]]);
    }
  }

  if (dataNode && dataNode.dataset.matomoEvents) {
    var events = JSON.parse(dataNode.dataset.matomoEvents);
    for (var eventIndex = 0; eventIndex < events.length; eventIndex++) {
      _paq.push(['trackEvent'].concat(events[eventIndex]));
    }
  }

  (function () {
    var u = 'https://stats.mama.media/';
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    if (dataNode && dataNode.dataset.siteId) {
      _paq.push(['setSiteId', dataNode.dataset.siteId]);
    }
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
  })();
});
