$(document).on("ajax:beforeSend", ".live-chat__form", function () {
  return $(this).find("#live_chat_message_content").val("");
});

$(document).on("turbo:load", function () {
  $(".live-chat__chat-user-form-link").on("click", function (event) {
    event.preventDefault();

    $(event.currentTarget)
      .closest(".widget")
      .find(".live-chat__create-chat-user-form")
      .removeClass("d-none");
  });

  var $forms = $(".live-chat__create-chat-user-form");

  $forms.on("ajax:beforeSend", function (event) {
    var $target = $(event.currentTarget);

    $target
      .find(".live-chat__create-chat-user-form__content")
      .addClass("d-none");

    $target
      .find(".live-chat__create-chat-user-form__message")
      .addClass("d-none");

    $target
      .find(".live-chat__create-chat-user-form__loading")
      .removeClass("d-none");
  });

  $forms.on("ajax:complete", function (event) {
    var [data, status, _xhr] = event.detail;

    var $message, $target;
    var $target = $(event.currentTarget);

    $target
      .find(".live-chat__create-chat-user-form__loading")
      .addClass("d-none");

    var $message = $target.find(".live-chat__create-chat-user-form__message");

    $message
      .removeClass("d-none")
      .removeClass("text-danger")
      .text(data.responseText);

    if (status === "success") {
      $message.addClass("text-success");
    } else {
      $target
        .find(".live-chat__create-chat-user-form__content")
        .removeClass("d-none");

      $message.addClass("text-danger");
    }
  });
});
