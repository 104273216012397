function update_widget_form_question_form_control_visibility() {
  var question_kind = $("#widget_form_question_kind").val();
  if (!question_kind) {
    return;
  }

  $("fieldset.only-if-kind").addClass("hide");
  $("fieldset.only-if-kind." + question_kind).removeClass("hide");
}

$(document).on("click", ".presentation-widget-form-container a", function (e) {
  e.preventDefault();
  e.stopPropagation();

  var node = $(this);
  node.closest(".presentation-widget-form-container").css("opacity", "0.1");

  $.get(node.attr("href"), function (data) {
    node
      .closest(".presentation-widget-form-container")
      .html(data)
      .css("opacity", "1");

    initSelect2();
    update_widget_form_question_form_control_visibility();
  });
});

$(document).on(
  "ajax:before",
  ".presentation-widget-form-container",
  function (_event) {
    $(this).css("opacity", "0.1");
  }
);

$(document).on(
  "ajax:complete",
  ".presentation-widget-form-container",
  function (event) {
    var [data, _status, _xhr] = event.detail;

    $(this).html(data.responseText).css("opacity", "1");

    initSelect2();
    update_widget_form_question_form_control_visibility();
  }
);

$(document).on(
  "turbo:load ajax:complete",
  update_widget_form_question_form_control_visibility
);

$(document).on(
  "change",
  ".new_widget_form_question",
  update_widget_form_question_form_control_visibility
);

$(document).on(
  "change",
  ".edit_widget_form_question",
  update_widget_form_question_form_control_visibility
);
