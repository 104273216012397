$(document).on("turbo:load", function () {
  var submit_s3_upload;

  submit_s3_upload = function (event, options) {
    var $form, form;

    if (options == null) {
      options = {};
    }

    if (options.done != null) {
      return;
    }

    event.preventDefault();
    form = event.currentTarget;
    $form = $(form);

    if ($form.find(".s3-upload-file").val()) {
      $form.find(".d-none").removeClass("d-none");
      $form.find(".uploader").addClass("d-none");
      $form.find("button, input[type=submit]").prop("disable", true);

      window.setTimeout(function () {
        $form.trigger("submit", {
          done: true,
        });
      }, 2000);
    } else {
      alert("Please supply a file.");
    }
  };

  $(".s3-upload-file").closest("form").on("submit", submit_s3_upload);
});

// Movie upload
import Evaporate from "evaporate";
import sha256 from "crypto-js/sha256";
import SparkMD5 from "spark-md5";

window.movie_file_upload = function (options) {
  const uploader = Evaporate.create({
    signerUrl: options.signerUrl,
    aws_key: options.aws_key,
    awsRegion: options.awsRegion,
    bucket: options.bucket,
    cloudfront: true,
    computeContentMd5: true,
    cryptoMd5Method: (d) => btoa(SparkMD5.ArrayBuffer.hash(d, true)),
    cryptoHexEncodedHash256: sha256,
  });

  document
    .querySelector("#" + options.name + "_uploader_file")
    .addEventListener("change", (evt) => {
      uploader.then((evaporate) => {
        window.evaporate = evaporate;

        $("#new_" + options.name + "_uploader-form").addClass("d-none");
        $("#new_" + options.name + "_upload.d-none").removeClass("d-none");

        Array.from(evt.target.files).forEach((file) => {
          evaporate
            .add({
              file,
              name: options.uploader_key,
              progress: (progressValue) =>
                $("#new_" + options.name + "_upload .progress-bar").css(
                  "width",
                  progressValue * 100 + "%"
                ),
              complete: (_xhr, awsKey) => console.log("Complete!", awsKey),
            })
            .then(
              (awsObjectKey) =>
                (window.location =
                  options.uploader_success_action_redirect +
                  "&original_filename=" +
                  file.name),
              (reason) =>
                console.log("File did not upload sucessfully:", reason)
            );
        });
      });
    });
};

window.project_file_upload = function(options) {
  const uploader = Evaporate.create({
    signerUrl: options.signerUrl,
    aws_key: options.aws_key,
    awsRegion: options.awsRegion,
    bucket: options.bucket,
    cloudfront: true,
    computeContentMd5: true,
    cryptoMd5Method: (d) => btoa(SparkMD5.ArrayBuffer.hash(d, true)),
    cryptoHexEncodedHash256: sha256,
  });

  document.querySelector('#file-field').addEventListener('change', (evt) => {
    uploader.then((evaporate) => {
      window.evaporate = evaporate;

      $('form#new_project_file_uploader').addClass('hide')
      $('#new_project_file_upload.hide').removeClass('hide')

      Array.from(evt.target.files).forEach((file) => {
        evaporate.add({
          file,
          name: options.name,
          progress: (progressValue) => $('#new_project_file_upload .progress-bar').css('width', (progressValue * 100) + '%'),
          complete: (_xhr, awsKey) => console.log('Complete!', awsKey),
        }).then(
          (awsObjectKey) => window.location = (options.success_action_redirect + '?original_filename=' + file.name),
          (reason) => console.log('File did not upload sucessfully:', reason)
        );
      });
    });
  });
}

window.media_asset_file_upload = function (options) {
  const uploader = Evaporate.create({
    signerUrl: options.signerUrl,
    aws_key: options.aws_key,
    awsRegion: options.awsRegion,
    bucket: options.bucket,
    cloudfront: true,
    computeContentMd5: true,
    cryptoMd5Method: (d) => btoa(SparkMD5.ArrayBuffer.hash(d, true)),
    cryptoHexEncodedHash256: sha256,
  });

  document.querySelector("#file-field").addEventListener("change", (evt) => {
    uploader.then((evaporate) => {
      window.evaporate = evaporate;

      $("form#new_media_asset_file_uploader").addClass("hide");
      $("#new_media_asset_file_upload.hide").removeClass("hide");

      Array.from(evt.target.files).forEach((file) => {
        evaporate
          .add({
            file,
            name: options.name,
            progress: (progressValue) =>
              $("#new_media_asset_file_upload .progress-bar").css(
                "width",
                progressValue * 100 + "%"
              ),
            complete: (_xhr, awsKey) => console.log("Complete!", awsKey),
          })
          .then(
            (awsObjectKey) =>
              (window.location =
                options.success_action_redirect +
                "?original_filename=" +
                file.name),
            (reason) => console.log("File did not upload sucessfully:", reason)
          );
      });
    });
  });
};
