import consumer from "./consumer";

const CurrentlyOnlineSubscription = consumer.subscriptions.create(
  "CurrentlyOnlineChannel",
  {
    onLoad() {
      if (
        document.body.classList.contains(
          "allow_others_to_see_which_page_i_am_on"
        )
      ) {
        this.send({ location: window.location.pathname });
      }

      this.updateSocialBox();
      window.initLiveChatChannels();
    },

    connected() {
      this.send({ ping: new Date() });

      // Connected, so display the social box
      this.socialBox = document.querySelector("#social-box");
      if (!this.socialBox) {
        return;
      }
      this.socialBox.classList.remove("d-none");

      this.socialBox
        .querySelector(".btn-toggle")
        .addEventListener("click", (_event) => {
          this.toggleSocialBox();
        });

      this.onLoad();
    },

    received(data) {
      if (!this.container) {
        this.container = document.getElementById("social-box");
      }

      if (!this.container) {
        return;
      }

      if (!this.online_container) {
        this.online_container = this.container.querySelector(".online");
      }

      if (data.favorited_users) {
        this.processFavoritedUsers(data.favorited_users);
      }

      if (data.unread_users) {
        this.processUser(data.unread_users, false);
      }

      if (data.online) {
        this.processUser(data.online, true);
      }

      if (data.chatroom) {
        this.processChatroom(data.chatroom);
      }

      if (data.live_chat) {
        this.processLiveChat(data.live_chat);
      }

      if (
        this.container.querySelector(
          "ul.online li:not(.current-user):not(.nobody)"
        )
      ) {
        var nobody = this.container.querySelector("li.nobody");
        if (nobody) {
          nobody.remove();
        }
      }

      this.updateChat();
      this.updateHasUnread();
    },

    processUser(data, isOnline) {
      this.online_container
        .querySelectorAll(".status.online")
        .forEach((item, _item_index) => {
          item.classList.remove("online");
        });

      this.online_container
        .querySelectorAll(".location")
        .forEach((item, _item_index) => {
          item.classList.add("d-none");
        });

      data.forEach((item, _item_index) => {
        this.ensureUser(item.id, item.name, item.allow_message, item.unread);
        if (isOnline) {
          this.updateUserOnlineStatus(item.id);
        }
        this.updateUserLocation(item.id, item.location);
      });
    },

    processFavoritedUsers(data) {
      this.online_container
        .querySelectorAll(".favorite")
        .forEach((item, _item_index) => {
          item.classList.remove("favorite");
        });

      this.online_container
        .querySelectorAll(".favorite-icon.fa-heart")
        .forEach((item, _item_index) => {
          item.classList.remove("fa-heart");
          item.classList.add("fa-heart-o");
        });

      data.forEach((item, _item_index) => {
        this.ensureUser(item.id, item.name, item.allow_message, item.unread);
        this.updateFavorite(item.id);
      });
    },

    processChatroom(chatroom) {
      var per_page_chat_button =
        this.socialBox.querySelector(".btn-per-page-chat");
      if (chatroom == "-" && per_page_chat_button) {
        per_page_chat_button.remove();
        if (this.currentChatCategory == "page") {
          this.removeChat();
        }
      } else if (chatroom != "-" && !per_page_chat_button) {
        per_page_chat_button = this.socialBox
          .querySelector(".actions")
          .appendChild(document.createElement("div"));
        per_page_chat_button.classList.add("btn-per-page-chat");
        var icon = per_page_chat_button.appendChild(
          document.createElement("i")
        );
        icon.classList.add("fa");
        icon.classList.add("fa-comment-o");
        per_page_chat_button.title = "Current page chat";
        per_page_chat_button.dataset.chatroom = chatroom;
        per_page_chat_button.addEventListener("click", (_event) => {
          this.toggleChat(chatroom, "page");
        });
      }
    },

    ensureUser(id, name, allowMessage, hasUnread) {
      var user_node = this.online_container.querySelector(
        '[data-id="' + id + '"]'
      );

      if (!user_node) {
        user_node = this.online_container.appendChild(
          document.createElement("li")
        );
        user_node.dataset.id = id;

        if (id == parseInt(document.body.dataset.currentUser)) {
          user_node.classList.add("current-user");
        }
      }

      var status_container = user_node.querySelector(".status");
      if (!status_container) {
        status_container = user_node.appendChild(document.createElement("div"));
        status_container.classList.add("status");
      }

      var data_container = user_node.querySelector(".data");
      if (!data_container) {
        data_container = user_node.appendChild(document.createElement("div"));
        data_container.classList.add("data");
      }

      var name_container = user_node.querySelector(".name");
      if (!name_container) {
        name_container = data_container.appendChild(
          document.createElement("div")
        );
        name_container.classList.add("name");
      }

      name_container.innerHTML = name;

      if (
        document.body.classList.contains(
          "allow_others_to_see_which_page_i_am_on"
        )
      ) {
        var location_container = user_node.querySelector(".location");
        if (!location_container) {
          location_container = data_container.appendChild(
            document.createElement("div")
          );
          location_container.classList.add("location");
        }
        location_container.classList.add("text-muted");
        location_container.innerText = "No location";
      }

      var toolbar_container = user_node.querySelector(".toolbar");
      if (!toolbar_container) {
        toolbar_container = user_node.appendChild(
          document.createElement("div")
        );
        toolbar_container.classList.add("toolbar");

        var message_button = user_node.querySelector(".btn-message");
        if (allowMessage && id == document.body.dataset.currentUser) {
          // lets not send messages to ourself
          allowMessage = false;
        }
        if (allowMessage && !message_button) {
          message_button = toolbar_container.appendChild(
            document.createElement("div")
          );
          message_button.classList.add("btn-message");

          var icon = message_button.appendChild(document.createElement("i"));
          icon.classList.add("fa");
          icon.classList.add("fa-comment-o");
          icon.title = "Chat";
          message_button.addEventListener("click", (_event) => {
            this.enableChat(id, "user");
          });
        } else if (!allowMessage && message_button) {
          message_button.remove();
          message_button = false;
        }

        var favorite_toggle = toolbar_container.appendChild(
          document.createElement("div")
        );
        favorite_toggle.classList.add("favorite-toggle");
        favorite_toggle.addEventListener("click", (_event) => {
          this.send({ toggleFavorite: id });
        });

        var favorite_icon = favorite_toggle.appendChild(
          document.createElement("i")
        );
        favorite_icon.classList.add("favorite-icon");
        favorite_icon.classList.add("fa");
        favorite_icon.classList.add("fa-heart-o");
      }

      var message_button = user_node.querySelector(".btn-message");
      if (message_button) {
        if (hasUnread) {
          message_button.classList.add("unread");
        } else {
          message_button.classList.remove("unread");
        }
      }
    },

    updateUserOnlineStatus(id) {
      var status_container = this.online_container.querySelector(
        '[data-id="' + id + '"] .status'
      );

      if (!status_container) {
        return;
      }

      status_container.classList.add("online");
    },

    updateUserLocation(id, location) {
      if (!location) {
        return;
      }

      var location_container = this.online_container.querySelector(
        '[data-id="' + id + '"] .location'
      );

      if (!location_container) {
        return;
      }

      location_container.classList.remove("text-muted");
      location_container.classList.remove("d-none");
      location = JSON.parse(location);
      if (location.url) {
        location_container.innerText = "";
        var link = location_container.querySelector("a");
        if (!link) {
          link = location_container.appendChild(document.createElement("a"));
        }
        link.href = location.url;
        link.innerText = location.label;
      } else {
        location_container.innerText = location.label || "Other";
      }
    },

    updateFavorite(id) {
      var user_node = this.online_container.querySelector(
        '[data-id="' + id + '"]'
      );

      if (!user_node) {
        return;
      }

      user_node.classList.add("favorite");

      var favorite_icon = user_node.querySelector(".favorite-icon.fa-heart-o");
      if (favorite_icon) {
        favorite_icon.classList.remove("fa-heart-o");
        favorite_icon.classList.add("fa-heart");
      }
    },

    enableSocialBox() {
      this.socialBox.querySelector(".box-content").classList.remove("d-none");
      this.socialBox.classList.add("enabled");
      this.removeChat();
    },

    disableSocialBox() {
      this.socialBox.querySelector(".box-content").classList.add("d-none");
      this.socialBox.classList.remove("enabled");
    },

    updateSocialBox() {
      if (!this.socialBox) {
        return;
      }

      if (this.isSocialBoxEnabled()) {
        this.enableSocialBox();
      } else {
        this.disableSocialBox();
      }
    },

    isSocialBoxEnabled() {
      return window.localStorage.getItem("social-box-enabled") != 0;
    },

    toggleSocialBox() {
      if (this.isSocialBoxEnabled()) {
        window.localStorage.setItem("social-box-enabled", 0);
        this.updateSocialBox();
      } else {
        window.localStorage.setItem("social-box-enabled", 1);
        this.updateSocialBox();
      }
    },

    removeChat() {
      var currentChat = this.socialBox.querySelector(".live-chat-body");
      if (currentChat) {
        window.localStorage.removeItem("current-chat-room");
        window.localStorage.removeItem("current-chat-category");
        currentChat.remove();
        this.socialBox.classList.remove("chat-enabled");
      }
    },

    toggleChat(chatroom, category) {
      var currentChat = this.socialBox.querySelector(".live-chat-body");
      if (currentChat) {
        this.removeChat();
        window.initLiveChatChannels();
      } else {
        this.enableChat(chatroom, category);
        window.initLiveChatChannels();
      }
    },

    enableChat(chatroom, category) {
      window.localStorage.setItem("current-chat-room", chatroom);
      window.localStorage.setItem("current-chat-category", category);

      if (this.isSocialBoxEnabled()) {
        this.toggleSocialBox();
      }

      this.currentChatCategory = category;
      this.send({ createChat: chatroom });
    },

    updateChat() {
      // Already processed on-load-chat-restore
      if (document.body.classList.contains("chat-onload")) {
        return;
      }

      if (!this.socialBox) {
        return;
      }

      var category = window.localStorage.getItem("current-chat-category");
      var chatroom = window.localStorage.getItem("current-chat-room");

      // Nothing to restore
      if (!category) {
        document.body.classList.add("chat-onload");
        return;
      }

      var restore_per_page_chat =
        category == "page" &&
        this.socialBox.querySelector(".btn-per-page-chat") &&
        this.socialBox.querySelector(".btn-per-page-chat").dataset.chatroom ==
          chatroom;

      var restore_user_chat =
        category == "user" &&
        this.socialBox.querySelector('[data-id="' + chatroom + '"]');

      if (restore_per_page_chat || restore_user_chat) {
        document.body.classList.add("chat-onload");
        this.enableChat(chatroom, category);
      }
    },

    processLiveChat(html) {
      this.removeChat();
      var live_chat_body = this.socialBox
        .querySelector(".live-chat-container")
        .appendChild(document.createElement("div"));
      live_chat_body.classList.add("live-chat-body");
      live_chat_body.innerHTML = html;
      this.socialBox.classList.add("chat-enabled");
      window.initLiveChatChannels();
    },

    updateHasUnread() {
      if (!this.socialBox) {
        return;
      }

      var hasUnread = this.socialBox.querySelector(
        "ul.online li:not(.current-user) .btn-message.unread"
      );

      if (hasUnread) {
        var badge = this.socialBox.querySelector(".btn-toggle .badge");
        if (!badge) {
          var badge = this.socialBox
            .querySelector(".btn-toggle")
            .appendChild(document.createElement("div"));
          badge.classList.add("badge");
          badge.classList.add("bg-danger");
          badge.classList.add("ms-1");
          badge.innerText = "!";
        }
      } else {
        var badge = this.socialBox.querySelector(".btn-toggle .badge");
        if (badge) {
          badge.remove();
        }
      }
    },
  }
);

window.CurrentlyOnlineSubscription = CurrentlyOnlineSubscription;

$(document).on("turbo:load", function () {
  CurrentlyOnlineSubscription.onLoad();
});

window.setInterval(function () {
  CurrentlyOnlineSubscription.send({ ping: new Date() });
}, 10000);
