import consumer from "./consumer"

// Note: requires that all nodes are enclosed in one (e.g. a parent div)
const htmlToNode = (html) => {
  return new DOMParser().parseFromString(html, 'text/html').body.childNodes[0]
}

const scrollToBottom = (messagesContainer) => {
  if (messagesContainer) {
    messagesContainer.scrollTo(0, messagesContainer.scrollHeight)
  }
}

const buildMessageNode = (data) => {
    const html = `
      <div class="live-chat__message">
        <span class="live-chat__message__author">${data.author}</span>
        <span class="live-chat__message__content">${data.content}</span>
      </div>
    `
  return htmlToNode(html)
}

const channelCallbacks = (element, id) => {
  return {
    received(data) {
      const messagesContainer = element.querySelector('.live-chat__messages')

      const shouldScrollAfter = (messagesContainer.scrollTop + messagesContainer.offsetHeight) ==
                                 messagesContainer.scrollHeight

      messagesContainer.append(buildMessageNode(data))

      if ($(messagesContainer).is(':visible')) {
        this.send({ read: true });
      }

      if (shouldScrollAfter) scrollToBottom(messagesContainer)
    }
  }
}

const initLiveChatChannel = liveChat => {
  const id = liveChat.dataset['id']

  consumer.subscriptions.create({ channel: 'LiveChatChannel', id: id }, channelCallbacks(liveChat, id))
}

window.initLiveChatChannels = () => {
  consumer.subscriptions.subscriptions.forEach((subscription) => {
    if (/LiveChatChannel/.test(subscription.identifier)) {
      subscription.unsubscribe();
    }
  });

  const liveChats = document.getElementsByClassName('live-chat')

  for (let liveChat of liveChats) {
    initLiveChatChannel(liveChat)

    const messagesContainer = liveChat.querySelector(".live-chat__messages");
    scrollToBottom(messagesContainer);
  }
}

document.addEventListener('turbo:load', window.initLiveChatChannels)
