function process_movie_publicly_playable_state() {
  state = $("#movie_publicly_playable").prop('checked');

  $('#movie_findable').prop('disabled', state);
  $("#movie_playable").prop("disabled", state);
  $("#movie_rental_cost").prop("disabled", state);
  $("#movie_rental_duration").prop("disabled", state);
  $("#movie_viewer_count_limit").prop("disabled", state);
  $("#movie_viewer_count_limit_roles").prop("disabled", state);
}

$(document).on(
  "turbo:load data-remote-target-refresh",
  process_movie_publicly_playable_state
);

$(document).on(
  "change",
  "#movie_publicly_playable",
  process_movie_publicly_playable_state
);
