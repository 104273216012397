function loadJitsi() {
  let widgets = $(".load-jitsi:not(.loaded)");
  let domain = "jitsi.mama.media";

  // No widgets to show
  if (widgets.length == 0) {
    return;
  }

  // Widgets to show, but Jitsi hasn't been loaded yet (this happens when you visit the page via turbo)
  if (typeof JitsiMeetExternalAPI == "undefined") {
    window.setTimeout(loadJitsi, 100);
    return;
  }

  $(".load-jitsi:not(.loaded)").each(function () {
    let options = {
      roomName: this.dataset.jitsiRoomName,
      width: "",
      height: "",
      jwt: this.dataset.jitsiJwt,
      parentNode: document.querySelector(this.dataset.jitsiParentNode),
    };

    new JitsiMeetExternalAPI(domain, options);

    this.classList.add('loaded');
  });
}

$(document).on("turbo:load", function () {
  loadJitsi();
});
