$.fn.raty.defaults.half = false;
$.fn.raty.defaults.halfShow = true;
$.fn.raty.defaults.path = "/assets";
$.fn.raty.defaults.cancel = false;

var bindRatingSystem = function () {
  $(".star").each(function () {
    var $readonly = $(this).attr("data-readonly") == "true";
    var $half = $(this).attr("data-enable-half") == "true";
    var $halfShow = $(this).attr("data-half-show") == "true";
    var $single = $(this).attr("data-single") == "true";
    $(this).raty({
      score: function () {
        return $(this).attr("data-rating");
      },
      number: function () {
        return $(this).attr("data-star-count");
      },
      half: $half,
      halfShow: $halfShow,
      single: $single,
      path: $(this).attr("data-star-path"),
      starOn: $(this).attr("data-star-on"),
      starOff: $(this).attr("data-star-off"),
      starHalf: $(this).attr("data-star-half"),
      cancel: $(this).attr("data-cancel"),
      cancelPlace: $(this).attr("data-cancel-place"),
      cancelHint: $(this).attr("data-cancel-hint"),
      cancelOn: $(this).attr("data-cancel-on"),
      cancelOff: $(this).attr("data-cancel-off"),
      noRatedMsg: $(this).attr("data-no-rated-message"),
      round: $(this).attr("data-round"),
      space: $(this).attr("data-space"),
      target: $(this).attr("data-target"),
      targetText: $(this).attr("data-target-text"),
      targetType: $(this).attr("data-target-type"),
      targetFormat: $(this).attr("data-target-format"),
      targetScoret: $(this).attr("data-target-score"),
      readOnly: $readonly,
      click: function (score, evt) {
        var _this = this;
        if (score == null) {
          score = 0;
        }
        $.post(
          "/rate",
          {
            score: score,
            dimension: $(this).attr("data-dimension"),
            id: $(this).attr("data-id"),
            klass: $(this).attr("data-classname"),
          },
          function (data) {
            if (data) {
              // success code goes here ...

              if ($(_this).attr("data-disable-after-rate") == "true") {
                $(_this).raty("set", { readOnly: true, score: score });
              }
            }
          }
        );
      },
    });
  });
};

$(document).on("turbo:load", function () {
  bindRatingSystem();
});
