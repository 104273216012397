import RecordRTC from "@mrmaruf/recordrtc";

function hide_voicemail_message(form, delay) {
  delay = delay ? 3000 : 0;

  setTimeout(() => {
    console.log(form);
    form.find(".voicemail__message").addClass("d-none");
  }, delay);
}

function update_voicemail_duration(voicemail) {
  let ms = new Date().getTime() - voicemail.startedAt.getTime();
  voicemail
    .find(".voicemail__recording__duration")
    .text((ms / 1000).toFixed(1));

  if (voicemail.recorder.getState() == "recording") {
    window.setTimeout(() => {
      update_voicemail_duration(voicemail);
    }, 100);
  }
}

function init_voicemail_submission(voicemail) {
  var form = voicemail.find("form:first");
  var form_data = new FormData(form[0]);
  form_data.set("voicemail[file]", voicemail.recorder.blob, "recording.wav");

  var callback = (event) => {
    event.preventDefault();
    event.stopPropagation();

    hide_voicemail_message(voicemail);

    form.find(".voicemail__upload__icon").addClass("d-none");
    form.find(".voicemail__upload__loading").removeClass("d-none");

    $.ajax({
      url: form[0].action,
      type: form[0].method,
      data: form_data,
      processData: false,
      contentType: false,
    })
      .done(function () {
        form.off("submit", callback);
        form
          .find(".voicemail__playback-play, .voicemail__playback-stop")
          .addClass("d-none disabled");
        form.find(".voicemail__upload").addClass("disabled");
        form.find(".voicemail__message--success").removeClass("d-none");
        hide_voicemail_message(voicemail, true);
      })
      .fail(function () {
        form.find(".voicemail__message--fail").removeClass("d-none");
      })
      .always(function () {
        form.find(".voicemail__upload__icon").removeClass("d-none");
        form.find(".voicemail__upload__loading").addClass("d-none");
      });
  };

  form.on("submit", callback);
}

$(document).on("turbo:load", function () {
  $(".presentation-widget.voicemail").each((_voicemail_index, voicemail) => {
    voicemail = $(voicemail);
    voicemail.player = voicemail.find(".voicemail__player:first")[0];

    voicemail.find(".voicemail__record-start").on("click", () => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(async function (stream) {
          if (voicemail.recorder) {
            voicemail.recorder.reset();
          } else {
            voicemail.recorder = RecordRTC(stream, {
              type: "audio",
            });
          }

          voicemail.recorder.onStateChanged = function (state) {
            if (state == "recording") {
              voicemail.find(".voicemail__recording").removeClass("d-none");
              voicemail.find(".voicemail__not-recording").addClass("d-none");

              voicemail.startedAt = new Date();
              update_voicemail_duration(voicemail);
            }
          };

          voicemail.recorder.startRecording();
        });
    });

    voicemail.find(".voicemail__record-stop").on("click", () => {
      voicemail.find(".voicemail__recording").addClass("d-none");
      voicemail.find(".voicemail__not-recording").removeClass("d-none");
      voicemail
        .find(".voicemail__playback-play")
        .removeClass("disabled d-none");
      voicemail.find(".voicemail__upload").removeClass("disabled d-none");
      voicemail.recorder.stopRecording((blobURL) => {
        $(voicemail.player).attr(
          "src",
          URL.createObjectURL(voicemail.recorder.blob)
        );

        init_voicemail_submission(voicemail);
      });
    });

    voicemail.find(".voicemail__playback-play").on("click", () => {
      voicemail.player.play();
    });

    voicemail.find(".voicemail__playback-stop").on("click", () => {
      voicemail.player.pause();
      voicemail.player.currentTime = 0;
    });

    $(voicemail.player).on("pause ended", () => {
      voicemail.find(".voicemail__playback-stop").addClass("disabled d-none");
      voicemail
        .find(".voicemail__playback-play")
        .removeClass("disabled d-none");
    });

    $(voicemail.player).on("play", () => {
      voicemail
        .find(".voicemail__playback-stop")
        .removeClass("disabled d-none");
      voicemail.find(".voicemail__playback-play").addClass("disabled d-none");
    });

    voicemail.find(".voicemail__upload").on("click", function () {
      voicemail.find("form").trigger("submit");
    });
  });
});
