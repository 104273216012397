var play_next_voicemail_message;

$(document).on("turbo:load", function () {
  window.project_voicemail_audio = new Audio();
  window.project_voicemail_audio.autoplay = true;
  window.project_voicemail_audio.addEventListener("playing", function (event) {
    return $("i.fa-spin")
      .removeClass("fa-spin")
      .removeClass("fa-spinner")
      .addClass("fa-stop");
  });
  return window.project_voicemail_audio.addEventListener(
    "ended",
    function (event) {
      $("i.fa-stop").removeClass("fa-stop").addClass("fa-play");
      if (window.loop_voicemail != null) {
        return play_next_voicemail_message();
      }
    }
  );
});

$(document).on('click', '.btn-play-voicemail', function() {
  if ($('i', this).hasClass('fa-stop')) {
    $('i', this).removeClass().addClass('fa').addClass('fa-play');
    return window.project_voicemail_audio.pause();
  } else {
    $('i.fa-stop').removeClass('fa-stop').addClass('fa-play');
    $('i', this).removeClass().addClass('fa').addClass('fa-spinner').addClass('fa-spin');
    $(this).addClass('played');
    window.project_voicemail_audio.src = $(this).data('source');
    return window.project_voicemail_audio.load();
  }
});

play_next_voicemail_message = function() {
  var next_voicemail;
  next_voicemail = $('.btn-play-voicemail:not(.played):first');
  if (next_voicemail.length > 0) {
    return next_voicemail.trigger('click');
  } else {
    return $.get($('.btn-loop-voicemail').data('update'), function(data, _status, _xhr) {
      $('#voicemails').html(data);
      $('.btn-loop-voicemail').hide();
      return play_next_voicemail_message();
    });
  }
};

$(document).on('click', '.btn-loop-voicemail', function() {
  window.loop_voicemail = true;
  $('.btn-loop-voicemail').hide();
  return play_next_voicemail_message();
});
