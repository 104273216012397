$(document).on("turbo:load", function () {
  $(".countdown-timer:not(.complete").each(function () {
    this.innerHTML = this.innerHTML.replace(
      /(\[COUNTDOWN\])/g,
      '<span class="countdown-value"><span class="countdown-days">?</span> <span class="countdown-hours">?</span> <span class="countdown-minutes">?</span> <span class="countdown-seconds">?</span></span>'
    );
  });

  update_countdown();
});

const update_countdown = function () {
  $(".countdown-timer:not(.complete").each(function () {
    const countDownDate = this.dataset.countdownUntil;
    const reloadAfter = typeof this.dataset.countdownReload != "undefined";

    const now = new Date().getTime();

    const distance = countDownDate - now;

    if (distance < 0 && reloadAfter) {
      window.location = window.location;
    }

    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    [].forEach.call(
      this.querySelectorAll(".countdown-value"),
      function (element) {
        element.querySelector(".countdown-days").innerText = days;
        element.querySelector(".countdown-hours").innerText = hours;
        element.querySelector(".countdown-minutes").innerText = minutes;
        element.querySelector(".countdown-seconds").innerText = seconds;

        if (distance < 0) {
          element.querySelector(".countdown-days").innerText = "0";
          element.querySelector(".countdown-hours").innerText = "0";
          element.querySelector(".countdown-minutes").innerText = "0";
          element.querySelector(".countdown-seconds").innerText = "0";
          if (this.classList) { this.classList.add("complete"); }
        }
      }
    );
  });
};

setInterval(update_countdown, 1000);
