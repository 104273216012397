var InfiniteScroller,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

InfiniteScroller = (function() {
  function InfiniteScroller(container) {
    this.on_scroll = bind(this.on_scroll, this);
    this.container = container;
    this.current_page = 1;
    this.loading = false;
    console.log(container);
    window.addEventListener('scroll', this.on_scroll);
  }

  InfiniteScroller.prototype.on_scroll = function() {
    if (this.loading || window.innerHeight + window.scrollY < document.body.offsetHeight - 1000) {
      return;
    }
    return this.load_next_page();
  };

  InfiniteScroller.prototype.load_next_page = function() {
    this.loading = true;
    this.current_page = this.current_page + 1;
    return $.get($(this.container).data('source').replace(/PAGE/, this.current_page), (function(_this) {
      return function(data, _status, _xhr) {
        _this.container.innerHTML += data;
        if (data !== '') {
          return _this.loading = false;
        }
      };
    })(this));
  };

  return InfiniteScroller;
})();

$(document).on("turbo:load", function () {
  $(".infinite-scroll").each(function () {
    new InfiniteScroller(this);
  });
});
