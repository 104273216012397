var checkSessionTimeout, clearTimeouts, reloadSessionTimer;

window.timeouts = {
  "sessiontimeout": [],
  "sessiontimer": []
};

clearTimeouts = function(namespace) {
  var i, len, ref, timeout;
  ref = timeouts[namespace];
  for (i = 0, len = ref.length; i < len; i++) {
    timeout = ref[i];
    clearTimeout(timeout);
  }
  return timeouts[namespace] = [];
};

reloadSessionTimer = function() {
  clearTimeouts("sessiontimer");
  $.get("/session_timer", {}, function(data) {
    return $(".user-session-timer").html(data);
  });
  return timeouts.sessiontimer.push(window.setTimeout(function() {
    return reloadSessionTimer();
  }, 60000));
};

checkSessionTimeout = function() {
  clearTimeouts("sessiontimeout");
  if ($("#user-signed-in").length > 0) {
    if ((typeof player !== "undefined" && player !== null) && player.isPlaying()) {
      $.post($("#user-signed-in").data("action-performed-url"), {});
    }
    $.get("/check_session_timeout");
    return timeouts.sessiontimeout.push(window.setTimeout(function() {
      return checkSessionTimeout();
    }, 60000));
  }
};

$(document).on("turbo:load", function () {
  timeouts.sessiontimer.push(
    window.setTimeout(function () {
      return reloadSessionTimer();
    }, 60000)
  );
  return timeouts.sessiontimeout.push(
    window.setTimeout(function () {
      return checkSessionTimeout();
    }, 60000)
  );
});
