import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "container"];

  connect() {
    $(this.selectTarget).on("select2:select", this.update.bind(this));

    this.update();
  }

  disconnect() {
    $(this.selectTarget).off("select2:select", this.update.bind(this));
  }

  update() {
    let selected = this.selectTarget.value.replace(/_/g, "-");

    this.containerTargets.forEach((container) => {
      if (container.classList.contains(selected)) {
        container.classList.remove("d-none");
      } else {
        container.classList.add("d-none");
      }
    });

    document.dispatchEvent(new Event("select2:refresh"))
  }
}
