import subjx from "./subjx-custom";

$(document).on("turbo:load", function () {
  $.each($(".floating-presentation-widget .widget"), function () {
    let widget = this;

    const xDraggables = subjx(widget).drag();

    $(".btn-float-enable", this).on("click", function () {
      $(this).closest(".floating-presentation-widget").addClass("floating");
    });

    if ($(this).closest(".floating-presentation-widget").hasClass("floating")) {
      xDraggables.forEach((item) => {
        item.exeDrag({
          dx: $(document.body).width() - 475 - 25,
          dy: 25,
        });
      });
    }

    xDraggables.forEach((item) => {
      item.disable();
    });

    $(".btn-controls-enable", this).on("click", function () {
      $(".btn-controls-enable", widget).addClass("hide");
      $(".btn-controls-disable", widget).removeClass("hide");

      widget.classList.add("dragging");

      xDraggables.forEach((item) => {
        item.enable({
          proportions: false,
          draggable: true,
          resizable: true,
          rotatable: false,
          onResize({ _clientX, _clientY, _dx, _dy, _width, height }) {
            this.el.parentNode.querySelector(".sjx-wrapper").style.marginTop =
              0 - height + "px";
          },
          onInit({ _el }) {
            xDraggables.forEach((item) => {
              item.exeResize({
                dx: 0,
                dy: 0,
              });
            });
          },
        });
      });
    });

    $(".btn-controls-disable", this).on("click", function () {
      $(".btn-controls-disable", widget).addClass("hide");
      $(".btn-controls-enable", widget).removeClass("hide");

      widget.classList.remove("dragging");

      xDraggables.forEach((item) => {
        item.disable();
      });
    });

    $(".btn-float-disable", this).on("click", function () {
      xDraggables.forEach((item) => {
        item.disable();
      });

      $(this).closest(".floating-presentation-widget").removeClass("floating");

      let style = $(this).closest(".widget").get(0).style;

      style.transform = null;
      style.width = null;
      style.height = null;
    });
  });
});
