$(document).on("turbo:load", function () {
  $(".filter-roles-link").on("click", function (e) {
    e.preventDefault();
    window.location.href = this.href + "?" + $("#roles").serialize();
  });

  $("select#role-filter").on("change", function (e) {
    $("#views-per-movie-modal form select#roles").select2(
      "val",
      $(this).val()
    );
  });
});
