import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    targetTop: Number
  }

  connect() {
  }

  scroll() {
    window.scrollTo({
      top: this.targetTopValue,
      behavior: "smooth",
    });
  }
}
