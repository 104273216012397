import consumer from "./consumer";
import smoothscroll from "smoothscroll-polyfill";

class PresentationPresenterModeClient {
  constructor(presentation, isPresenter) {
    this.isPresenter = isPresenter;

    smoothscroll.polyfill();

    this.presentation = presentation;
    this.id = presentation.dataset["id"];
    this.enabled = false;
    this.available = false;

    if (!this.isPresenter) {
      this.bind_event_listeners();
    }

    consumer.subscriptions.create(
      { channel: "PresentationChannel", id: this.id },
      {
        received(data) {
          this.client.received(data);
        },
        client: this,
      }
    );

    if (!this.isPresenter && presentation.dataset["enabled"] == 1) {
      this.available = true;
      this.update_available_and_enabled();
    }

    if (this.isPresenter) {
      this.enabled = true;
    }
  }

  bind_event_listeners() {
    this.presentation
      .querySelector(".btn-enable")
      .addEventListener("click", this.enable.bind(this));

    this.presentation
      .querySelector(".btn-disable")
      .addEventListener("click", this.disable.bind(this));
  }

  enable() {
    this.enabled = true;
    this.update_available_and_enabled();
  }

  disable() {
    this.enabled = false;
    this.update_available_and_enabled();
  }

  received(data) {
    if (!this.isPresenter) {
      if (typeof data.presenter_mode_enabled !== "undefined") {
        this.toggle_available(data.presenter_mode_enabled);
      }

      if (typeof data.make_visible !== "undefined") {
        this.make_visible(data.make_visible);
      }

      if (typeof data.make_invisible !== "undefined") {
        this.make_invisible(data.make_invisible);
      }
    }

    if (!this.enabled) {
      return;
    }

    if (typeof data.play !== "undefined") {
      this.play(data.play);
    }

    if (typeof data.pause !== "undefined") {
      this.pause(data.pause);
    }

    if (!this.isPresenter) {
      if (typeof data.enter_full_screen !== "undefined") {
        this.enter_full_screen(data.enter_full_screen);
      }

      if (typeof data.exit_full_screen !== "undefined") {
        this.exit_full_screen(data.exit_full_screen);
      }

      if (typeof data.seek_to_here !== "undefined") {
        this.seek_to_here(data.seek_to_here);
      }
    }

    if (typeof data.scroll_to !== "undefined") {
      this.scroll_to(data.scroll_to);
    }
  }

  toggle_available(new_availability) {
    this.available = new_availability;
    this.update_available_and_enabled();
  }

  update_available_and_enabled() {
    if (this.available) {
      this.presentation
        .querySelector(".presenter-mode-client")
        .classList.remove("hide");
    } else {
      this.presentation
        .querySelector(".presenter-mode-client")
        .classList.add("hide");
    }

    if (this.enabled) {
      this.presentation
        .querySelector(".presenter-mode-client .disabled")
        .classList.add("hide");
      this.presentation
        .querySelector(".presenter-mode-client .enabled")
        .classList.remove("hide");
      document.body.classList.add("presenter-mode-following");
    } else {
      this.presentation
        .querySelector(".presenter-mode-client .disabled")
        .classList.remove("hide");
      this.presentation
        .querySelector(".presenter-mode-client .enabled")
        .classList.add("hide");
      document.body.classList.remove("presenter-mode-following");
    }
  }

  make_visible(widget_id) {
    let container = this.presentation.querySelector(
      '[data-widget-id="' + widget_id + '"]'
    );
    if (container) {
      container.classList.remove("hide");
      container.parentNode.classList.remove("empty");
    }
  }

  make_invisible(widget_id) {
    let container = this.presentation.querySelector(
      '[data-widget-id="' + widget_id + '"]'
    );
    if (container) {
      container.classList.add("hide");

      var any_visible = false;
      var siblings = container.parentNode.childNodes;
      for (
        var sibling_index = 0;
        sibling_index < siblings.length;
        sibling_index++
      ) {
        var sibling = siblings[sibling_index];
        if (sibling.nodeType == Node.ELEMENT_NODE) {
          if (!siblings[sibling_index].classList.contains("hide")) {
            any_visible = true;
          }
        }
      }
      if (!any_visible) {
        container.parentNode.classList.add("empty");
      }
    }
  }

  play(widget_id) {
    let bitmovinplayer = this.presentation.querySelector(
      '[data-widget-id="' + widget_id + '"] .bitmovinplayer-container'
    );

    if (bitmovinplayer) {
      let player = window.players[bitmovinplayer.id];
      if (player) player.play();
    } else {
      let video_element = this.presentation.querySelector(
        '[data-widget-id="' + widget_id + '"] video'
      );

      if (video_element) video_element.play();
    }
  }

  pause(widget_id) {
    let bitmovinplayer = this.presentation.querySelector(
      '[data-widget-id="' + widget_id + '"] .bitmovinplayer-container'
    );

    if (bitmovinplayer) {
      let player = window.players[bitmovinplayer.id];
      if (player) player.pause();
    } else {
      let video_element = this.presentation.querySelector(
        '[data-widget-id="' + widget_id + '"] video'
      );

      if (video_element) video_element.pause();
    }
  }

  enter_full_screen(widget_id) {
    let bitmovinplayer = this.presentation.querySelector(
      '[data-widget-id="' + widget_id + '"] .bitmovinplayer-container'
    );

    if (bitmovinplayer) {
      let player = window.players[bitmovinplayer.id];
      if (player) player.setViewMode('fullscreen');
    }
  }

  exit_full_screen(widget_id) {
    let bitmovinplayer = this.presentation.querySelector(
      '[data-widget-id="' + widget_id + '"] .bitmovinplayer-container'
    );

    if (bitmovinplayer) {
      let player = window.players[bitmovinplayer.id];
      if (player) player.setViewMode('inline');
    }
  }

  seek_to_here(data) {
    let widget_id = data[0];
    var timestamp = data[1];

    let bitmovinplayer = this.presentation.querySelector(
      '[data-widget-id="' + widget_id + '"] .bitmovinplayer-container'
    );

    if (bitmovinplayer) {
      let player = window.players[bitmovinplayer.id];
      if (player) player.seek(timestamp);
    }
  }

  scroll_to(widget_id) {
    let widget = this.presentation.querySelector(
      '[data-widget-id="' + widget_id + '"]'
    );
    if (!widget) return;

    var headerOffset = document
      .querySelector("nav.navbar")
      .getBoundingClientRect().height;

    var widgetPosition = widget.getBoundingClientRect().top;
    var offsetPosition = widgetPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
}

class PresentationPresenterModeServer {
  constructor(presentation) {
    this.presentation = presentation;
    this.id = presentation.dataset["id"];

    this.bind_event_listeners();

    this.subscription = consumer.subscriptions.create(
      { channel: "PresentationChannel", id: this.id },
      {
        client: this,
      }
    );
  }

  bind_event_listeners() {
    const scroll_to_elements = this.presentation.querySelectorAll(
      ".btn-presenter-mode-scroll-to"
    );
    for (let element of scroll_to_elements) {
      element.addEventListener("click", this.scroll_to.bind(this));
    }

    const play_elements = this.presentation.querySelectorAll(
      ".btn-presenter-mode-play"
    );
    for (let element of play_elements) {
      element.addEventListener("click", this.play.bind(this));
    }

    const pause_elements = this.presentation.querySelectorAll(
      ".btn-presenter-mode-pause"
    );
    for (let element of pause_elements) {
      element.addEventListener("click", this.pause.bind(this));
    }

    const enter_full_screen_elements = this.presentation.querySelectorAll(
      ".btn-presenter-mode-enter-full-screen"
    );
    for (let element of enter_full_screen_elements) {
      element.addEventListener("click", this.enter_full_screen.bind(this));
    }

    const exit_full_screen_elements = this.presentation.querySelectorAll(
      ".btn-presenter-mode-exit-full-screen"
    );
    for (let element of exit_full_screen_elements) {
      element.addEventListener("click", this.exit_full_screen.bind(this));
    }

    const seek_to_here_elements = this.presentation.querySelectorAll(
      ".btn-presenter-mode-seek-to-here"
    );
    for (let element of seek_to_here_elements) {
      element.addEventListener("click", this.seek_to_here.bind(this));
    }

    const make_visible_elements =
      this.presentation.querySelectorAll(".btn-make-visible");
    for (let element of make_visible_elements) {
      element.addEventListener("click", this.make_visible.bind(this));
    }

    const make_invisible_elements = this.presentation.querySelectorAll(
      ".btn-make-invisible"
    );
    for (let element of make_invisible_elements) {
      element.addEventListener("click", this.make_invisible.bind(this));
    }
  }

  play(event) {
    this.subscription.send({ play: event.srcElement.dataset["id"] });
  }

  pause(event) {
    this.subscription.send({ pause: event.srcElement.dataset["id"] });
  }

  enter_full_screen(event) {
    this.subscription.send({ enter_full_screen: event.srcElement.dataset["id"] });
  }

  exit_full_screen(event) {
    this.subscription.send({ exit_full_screen: event.srcElement.dataset["id"] });
  }

  seek_to_here(event) {
    let bitmovinplayer = document.body.querySelector(
      '[data-widget-id="' +
        event.srcElement.dataset["id"] +
        '"] .bitmovinplayer-container'
    );

    if (bitmovinplayer) {
      let player = window.players[bitmovinplayer.id];
      if (player) {
        this.subscription.send({
          seek_to_here: [event.srcElement.dataset["id"], player.getCurrentTime()],
        });
      }
    }
  }

  scroll_to(event) {
    this.subscription.send({ scroll_to: event.srcElement.dataset["id"] });
  }

  make_visible(event) {
    $(event.srcElement)
      .closest(".presenter-mode-options")
      .removeClass("is-invisible");

    this.subscription.send({ make_visible: event.srcElement.dataset["id"] });
  }

  make_invisible(event) {
    $(event.srcElement)
      .closest(".presenter-mode-options")
      .addClass("is-invisible");

    this.subscription.send({ make_invisible: event.srcElement.dataset["id"] });
  }
}

const initPresentationChannels = () => {
  const presentations = document.getElementsByClassName("presentation-channel");

  for (let presentation of presentations) {
    if (presentation.dataset["presenter"] == 1) {
      new PresentationPresenterModeServer(presentation);
      new PresentationPresenterModeClient(presentation, true);
    } else {
      new PresentationPresenterModeClient(presentation);
    }
  }
};

document.addEventListener("turbo:load", initPresentationChannels);
