var make_enbed_responsive;

make_enbed_responsive = function() {
  return $('.presentation-widget .ratio, .presentation-widget .aspect-16x9').each(function() {
    return $(this).height($(this).width() / 16 * 9);
  });
};

$(document).on("turbo:load ajax:complete", make_enbed_responsive);

$(window).on('resize video-player:play video-player:error', make_enbed_responsive);
