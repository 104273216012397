import consumer from "./consumer";

const initDigitalCinemaPackageStatusChannel = (digitalCinemaPackageStatus) => {
  const id = digitalCinemaPackageStatus.dataset["id"];

  consumer.subscriptions.create(
    { channel: "DigitalCinemaPackageStatusChannel", id: id },
    {
      received(data) {
        if (digitalCinemaPackageStatus.classList.contains("badge")) {
          const newBadge = document.createElement("div");
          newBadge.innerHTML = data["status_badge"];
          digitalCinemaPackageStatus.classList =
            newBadge.firstElementChild.classList;
          digitalCinemaPackageStatus.innerText =
            newBadge.firstElementChild.innerText;
        } else {
          digitalCinemaPackageStatus.innerHTML = data["status"];
        }
      },
    }
  );
};

window.initDigitalCinemaPackageStatusChannels = () => {
  consumer.subscriptions.subscriptions.forEach((subscription) => {
    if (/digitalCinemaPackageStatusChannel/.test(subscription.identifier)) {
      subscription.unsubscribe();
    }
  });

  const digitalCinemaPackageStatuses = document.getElementsByClassName(
    "digital-cinema-package-status"
  );

  for (let digitalCinemaPackageStatus of digitalCinemaPackageStatuses) {
    initDigitalCinemaPackageStatusChannel(digitalCinemaPackageStatus);
  }
};

document.addEventListener(
  "turbo:load",
  window.initDigitalCinemaPackageStatusChannels
);
