let options = {
  root: null,
  rootMargin: "0px",
  threshold: 1.0,
};

let callback = function (entries) {
  var fullyVisible = entries[0].intersectionRatio == 1;

  if (fullyVisible) {
    entries[0].target.classList.remove("outside-viewport");
  } else {
    entries[0].target.classList.add("outside-viewport");
  }
};

let intersectionObserver = new IntersectionObserver(callback, options);

$(document).on("turbo:load", function () {
  $(".form-actions.sticky").each(function () {
    intersectionObserver.observe(this);
  });
});
