import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  setTarget(event) {
    this.element.action = event.params.target;

    let csrf_param = document.querySelector('meta[name=csrf-param]').content;
    let csrf_token = document.querySelector('meta[name=csrf-token]').content;

    this.element.querySelector(`input[name=${csrf_param}]`) .value = csrf_token;
  }
}
