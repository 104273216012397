$(document).on("turbo:load", function () {
  // We can actually maybe use bitmovin's resize event.
  if (typeof ResizeObserver == "undefined") return;
  var $listingDescription = $(".mod-listing-page__main-feature__description");
  if ($listingDescription.length === 0) return;

  $(window).on("video-player:loaded", function () {
    var observer = new ResizeObserver(function (entries) {
      $.each(entries, function (_, entry) {
        if (
          entry.contentRect.height > 0 &&
          $listingDescription[0].height < entry.contentRect.height
        ) {
          $listingDescription.css("height", entry.contentRect.height + "px");
        }
      });
    });

    // select the container
    var element = $(".video-player > div")[0];
    observer.observe(element);
  });
});
