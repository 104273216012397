var wait_for_other_status;

wait_for_other_status = function() {
  var dataset;
  dataset = document.getElementById('mollie-pending-payment').dataset;
  return $.get(dataset.status, {}, (function(_this) {
    return function(result) {
      if (result.status !== 'pending') {
        return window.location = dataset.target;
      } else {
        return window.setTimeout(wait_for_other_status, 2500);
      }
    };
  })(this));
};

$(document).on('turbo:load', function() {
  if (!document.getElementById('mollie-pending-payment')) {
    return;
  }
  return window.setTimeout(wait_for_other_status, 2500);
});
