import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const destroyLink = this.element.querySelector("a.destroy");
    if (destroyLink) {
      destroyLink.addEventListener('click', (event) => {
        let form = this.element.closest('form');
        this.element.remove();

        event.preventDefault();
        event.stopPropagation();

        form.submit();

        return false;
      });
    }
  }
}
