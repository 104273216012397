import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("shown.bs.modal", (_event) => {
      window.players[this.element.getElementsByClassName('video-player')[0].id].play()
    });

    this.element.addEventListener("hidden.bs.modal", (_event) => {
      window.players[this.element.getElementsByClassName('video-player')[0].id].pause()
    });
  }
}
