import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "textArea" ]

  update({ params: { type } }) {
    if (type == 'inherit') {
      this.textAreaTarget.classList.add('d-none');
      this.textAreaTarget.value = '';
    } else if (type == 'value') {
      this.textAreaTarget.classList.remove('d-none');
    } else if (type == 'disable') {
      this.textAreaTarget.classList.add('d-none');
      this.textAreaTarget.value = 'false';
    }
  }
}
