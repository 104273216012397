var bindMessageThreadsListener,
  bindMovieSearchListeners,
  bindNotesListener;

$(document).on("turbo:load data-remote-target-refresh", function () {
  bindMovieSearchListeners();
  bindNotesListener();
  // bindRatingSystem();
  bindMessageThreadsListener();
});

bindMovieSearchListeners = function () {
  if ($("form#find-movie-form").length > 0) {
    $(document).on(
      "change",
      "form#find-movie-form select#edition",
      function () {
        $.ajax({
          url: $(this).data("sections-select-box-url"),
          data: {
            edition: $(this).val(),
          },
          success: function (data) {
            $("form#find-movie-form select#section")
              .select2("destroy")
              .replaceWith(data);
            return initSelect2();
          },
        });
      }
    );
  }
};

$.fn.observe = function (time, callback) {
  return this.each(function () {
    var form, serialized;
    form = $(this);
    serialized = form.serialize();
    return (window.formObserverPID = setInterval(function () {
      if (serialized !== form.serialize()) {
        serialized = form.serialize();
        return callback.call(form);
      }
    }, time * 1000));
  });
};

bindNotesListener = function () {
  $("textarea#note_text").observe(1, function (event) {
    var form = $("form#notes-form").get(0);
    window.Rails.fire(form, "submit");
  });
};

bindMessageThreadsListener = function () {
  $("#new-message-thread-modal form").on("submit", function (event) {
    if ($("#message_thread_body").val().replace(/[\s\r\n]/g, "") === "") {
      $("#new-message-thread-modal .alert").removeClass("hide");
      event.preventDefault();
      event.stopPropagation();
    }
  });
};

$(document).on("click", ".btn-views-per-movie", function (event) {
  event.preventDefault();

  $("#views-per-movie-modal form").attr("action", $(this).data("url"));
});

$(document).on("show.bs.modal", "#views-per-movie-modal", function (_event) {
  $("#movie_id").select2({ width: "100%" });
});
