import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // we need to have a copy of our event listener function to be able to remove it on disconnect
    this.scrollEventEventListener = this.scrollEvent.bind(this);
    document.addEventListener("scroll", this.scrollEventEventListener);

    this.scrollEvent();

    this.element.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }

  disconnect() {
    document.removeEventListener("scroll", this.scrollEventEventListener);
  }

  scrollEvent() {
    if (window.scrollY < 400) {
      this.element.classList.add("d-none");
    } else {
      this.element.classList.remove("d-none");
    }
  }
}
